<template>
  <b-sidebar
    id="assign-permission-role-sidebar"
    :visible="isAssignPermissionRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-assign-permission-role-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Assign Permission</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <b-form-group label="New Permission" label-for="New-Permission">
            <!-- {{ selected }} -->
            <v-select
              v-model="selected"
              multiple
              :close-on-select="false"
              :options="table_data"
              label="name"
              input-id="add-guests"
            >
            </v-select>
          </b-form-group>

          <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
  BAvatar,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import countries from "../../../../@fake-db/data/other/countries";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BAvatar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAssignPermissionRoleSidebarActive",
    event: "update:is-assign-permission-role-sidebar-active",
  },
  props: {
    isAssignPermissionRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    assignPermission: {
      type: Object,
      required: true,
    },
  },
  // watch: {
  //   isAssignPermissionRoleSidebarActive(val) {
  //     console.log("tabledata xxxx",this.table_data)
  //      this.selected = this.assignPermission.permission;
  //   },
  // },
  //   mixins: [togglePasswordVisibility],
  data() {
    return {
      selected: "",
      saveAssignValueArray: [],

      required,
      alphaNum,
      email,
      countries,
      roledata: {},
      table_data: [],
      add_new_role_data: "",
    };
  },
  created() {
    this.gettabledata();
  },
  //   computed: {
  //     passwordToggleIcon() {
  //       return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
  //     },
  //   },
  methods: {
    // SelectedPermssion(name) {
    //   console.log("console array select permission", name);
    // },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.roledata = {};
      this.selected = "";
    },
    onSubmit() {
      this.roledata.id = this.assignPermission.id;
      //   console.log("selected", this.selected);
      this.roledata.assignPermission = this.selected;

      this.add_new_role_data = this.roledata;
      //   console.log("selected", this.add_new_role_data);
      this.$emit("clickAssignPermission", this.add_new_role_data);
      this.$root.$emit(
        "bv::toggle::collapse",
        "assign-permission-role-sidebar"
      );
      //   if (this.roledata.password == this.roledata.password_confirmation) {
      //     this.add_new_user_data = this.roledata;
      //     this.$emit("clickaddNewUser", this.add_new_user_data);
      //     this.$root.$emit("bv::toggle::collapse", "add-new-users-sidebar");
      //   } else {
      //     this.showToast("danger", "top-left");
      //     // alert("Please confirm your password first");
      //   }

      //   console.log(this.add_new_level_data);
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/permission/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;

          // console.log(get_response.data);
        });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: "Please confirm your password first",
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
