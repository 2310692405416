<template>
  <b-sidebar
    id="add-edit-role-sidebar"
    :visible="isAddEditRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-edit-role-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Role</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->

          <validation-provider
            #default="validationContext"
            name="Role Name"
            rules="required"
          >
            <b-form-group label="Role Name" label-for="Role Name">
              <b-form-input id="Name" v-model="roledata.name" autofocus trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="New Permission" label-for="New-Permission">
            <!-- {{ selected }} -->
            <v-select
              v-model="selected"
              multiple
              :close-on-select="false"
              :options="table_data"
              label="name"
              input-id="add-guests"
            >
            </v-select>
          </b-form-group>

          <div
            class="d-flex bg-light text-light align-items px-3 py-2"
            style="position: absolute; bottom: 0%; left: 0%; width: 100%"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="addRole"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              style="margin-left: 10px"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BFormSelect,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "../../../../@fake-db/data/other/countries";
import store from "@/store";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BSidebar,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BInputGroupAppend,
    ToastificationContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddEditRoleSidebarActive",
    event: "update:is-add-edit-role-sidebar-active",
  },
  props: {
    isAddEditRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    editRoleData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isAddEditRoleSidebarActive(val) {
      var cloneRoleData = JSON.parse(JSON.stringify(this.table_data));
      // console.log("this.editRoleData", this.editRoleData);
      this.roledata.name = this.editRoleData.name;
      var newData = [];
      for (let index = 0; index < cloneRoleData.length; index++) {
        // console.log("cloneRoleData",cloneRoleData[index]._id);
        // console.log("this.editRoleData.OldObject",this.editRoleData.OldObject._id)
        for (let i = 0; i < this.editRoleData.OldObject.length; i++) {
          if (cloneRoleData[index]._id == this.editRoleData.OldObject[i]) {
            newData[index] = cloneRoleData[index];
          } else {
          }
        }
      }
      var filtered = newData.filter(function (el) {
        return el != null;
      });
      this.selected = filtered;
    },
  },
  //   mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      roledata: {
        name: "",
        permission: "",
      },
      add_new_role_data: "",
      table_data: [],
      selected: "",
      saveAssignValueArray: [],
    };
  },
  created() {
    this.gettabledata();
  },
  computed: {
    addRole() {
      if (this.roledata.name.length > 3) {
        return false;
      } else {
        return true;
      }
    },
  },

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.roledata = {
        name: "",
      };
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/permission/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.table_data = get_response.data;
        });
    },
    onSubmit() {
      this.roledata.id = this.editRoleData.id;
      this.roledata.permission = this.selected;
      this.add_new_role_data = this.roledata;
      // console.log("this.add_new_role_data", this.add_new_role_data);
      this.$emit("clickaddEditRole", this.add_new_role_data);
      this.$root.$emit("bv::toggle::collapse", "add-edit-role-sidebar");
    },
    showToast(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Form Submit",
            variant,
          },
        },
        {
          position,
        }
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
